import React, { useState } from 'react';
import { ReactComponent as BadIcon } from '../../img/bad_grade.svg';
import { ReactComponent as SadIcon } from '../../img/sad_grade.svg';
import { ReactComponent as MediumIcon } from '../../img/medium_grade.svg';
import { ReactComponent as GoodIcon } from '../../img/good_grade.svg';
import { ReactComponent as FunnyIcon } from '../../img/funny_grade.svg';

const Feedback = ({ user, trip, t }) => {

    const [userRate, setUserRate] = useState(user.rate);

    console.log(user);
    const handleUserRate = (rate) => {
        const user_info = JSON.parse(localStorage.getItem('user_info'));
        const body = {
            from_user: user_info.tg_id,
            to_user: user.tg_id,
            trip_id: trip,
            rate: rate
        }

        fetch('api/rate_user', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(response => response.json())
            .then(data => {
                setUserRate(true);
            })
            .catch(error => {
                setUserRate(false);
            });
    }

    return (
        !userRate ? (
            <div className="grade-faces">
                <BadIcon
                    onClick={() => handleUserRate(1)}
                    className="icon_small"
                />
                <SadIcon
                    onClick={() => handleUserRate(2)}
                    className="icon_small"
                />
                <MediumIcon
                    onClick={() => handleUserRate(3)}
                    className="icon_small"
                />
                <GoodIcon
                    onClick={() => handleUserRate(4)}
                    className="icon_small"
                />
                <FunnyIcon
                    onClick={() => handleUserRate(5)}
                    className="icon_small"
                />
            </div>
        ) : (
            userRate === true ? (
                <p className="feedback">{t('thanksfeedback')}</p>
            ) : (
                <div className="grade-faces">
                    <BadIcon
                        className={`icon_small ${userRate === 1 ? 'rate-1' : ''}`}
                    />
                    <SadIcon
                        className={`icon_small ${userRate === 2 ? 'rate-2' : ''}`}
                    />
                    <MediumIcon
                        className={`icon_small ${userRate === 3 ? 'rate-3' : ''}`}
                    />
                    <GoodIcon
                        className={`icon_small ${userRate === 4 ? 'rate-4' : ''}`}
                    />
                    <FunnyIcon
                        className={`icon_small ${userRate === 5 ? 'rate-5' : ''}`}
                    />
                </div>
            )
        )


    );
}

export default Feedback;