import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loadTelegramWebApp } from "../telegram/telegram";
import "./Trip.css";
import back from "../../img/back.svg";
import account from "../../img/account.svg";
import share from "../../img/share.svg";
import { ReactComponent as Male } from "../../img/sex_male.svg";
import { ReactComponent as Female } from "../../img/sex_female.svg";
import TripChat from "./TripChat";

export default function TripRun({
    selectedRoute,
    user_info,
    handleCloseClick,
    customClass,
    end,
    registrTrip,
    t
}) {
    const lang = localStorage.getItem('lang');

    const tripRef = useRef(null);
    const headerRef = useRef(null);
    const bottomRef = useRef(null);
    const mapRef = useRef(null);
    const navigate = useNavigate();
    const [chat, setChat] = useState(null);
    const [right, setRigth] = useState(true);
    let registr = false;

    if (user_info.birthday) {
        registr = true;
    } else {
        registr = false;
    }

    const handleRegistrButton = () => {
        navigate("/profile");
    };

    const getAge = (birthday) => {
        let year_month_day = birthday.split('-');
        let year = parseInt(year_month_day[0]);
        let month = parseInt(year_month_day[1]);
        let day = parseInt(year_month_day[2]);
        const birthdat_date = new Date(year, month - 1, day);
        const today = new Date();
        const diff = today - birthdat_date;
        const ageDate = new Date(diff);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const handleJoinButton = (route) => {
        // const user_info = JSON.parse(localStorage.getItem("user_info"));
        // route.user_id = parseInt(user_info.tg_id);
        // fetch("api/trip", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(route),
        // }).then((response) => {
        //     loadTelegramWebApp().then(() => {
        //         if (window.Telegram && window.Telegram.WebApp) {
        //             const webApp = window.Telegram.WebApp;
        //             webApp.close();
        //         }
        //     });
        // });
        setChat(route);
    };

    const handleRegistrTrip = (route) => {
        const user_info = JSON.parse(localStorage.getItem("user_info"));
        route.user_id = parseInt(user_info.tg_id);
        fetch("api/trip", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(route),
        }).then(response => response.json())
            .then(data => {
                localStorage.setItem('user_history', JSON.stringify(data));
                setChat(route);
            });

    }

    const handleCloseChat = () => {
        setChat(null);
    }

    const handleLeaveButton = (route) => {
        const user_info = JSON.parse(localStorage.getItem("user_info"));
        route.user_id = parseInt(user_info.tg_id);
        fetch("api/trip", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(route),
        }).then(response => response.json())
            .then(data => {
                localStorage.setItem('user_history', JSON.stringify(data));
                handleCloseClick();
            });
    };

    const copyTripLink = async (selectedRoute) => {
        const tripObject = '{"trip" : ' + selectedRoute.id + '}';
        const inviteLink = 'https://t.me/meetell_bot/app?startapp=' + btoa(tripObject);

        if (window.Telegram && window.Telegram.WebApp) {
            const webApp = window.Telegram.WebApp;
            webApp.HapticFeedback.impactOccurred("soft");
            webApp.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=Делюсь с тобой маршрутом!`);
        }

    };

    console.log(selectedRoute);
    const fullRoute = selectedRoute.registered_users.length === selectedRoute.max_user;

    if (registrTrip) {
        const user_info = JSON.parse(localStorage.getItem('user_info'));
        const user_photo = JSON.parse(localStorage.getItem('user_photo'));
        let userExists = selectedRoute.registered_users.some(registeredUser =>
            registeredUser.user && registeredUser.user.tg_id === user_info.tg_id);
        if (!userExists) {
            selectedRoute.registered_users.push({
                user: {
                    tg_id: user_info.tg_id,
                    level: user_info.level.id,
                    user_name: user_info.user_name,
                    name: user_info.name,
                    birthday: user_info.birthday,
                    sex: user_info.sex,
                    photo_low: user_photo.photo_low,
                    rate: null
                }
            });
        }
    }

    useEffect(() => {
        if (headerRef.current && bottomRef.current && mapRef.current) {
            let rectHeader = headerRef.current.getBoundingClientRect();
            let rectBottom = bottomRef.current.getBoundingClientRect();
            mapRef.current.style.height = rectBottom.top - rectHeader.bottom + 'px';
            mapRef.current.style.width = rectBottom.top - rectHeader.bottom + 'px';
            console.log("");
        }
    })

    return (
        <div ref={tripRef} className={`route_info_slide_in ${customClass}`}>
            {!chat && (
                <>
                    <div ref={headerRef} className='route_head'>
                        <div className='route_header'>
                            <div className='route_close'>
                                <button className="close_button" onClick={handleCloseClick}>
                                    <img src={back} alt='' />
                                </button>
                            </div>
                            <div className="route_info_content">
                                <p className='route_info_id'>{t('route')} №{selectedRoute.id}</p>
                                <p className='route_info_date'>{selectedRoute.date}</p>
                                {lang === 'ru' ? (
                                    <h4>{selectedRoute.name}</h4>
                                ) : (
                                    <h4>{selectedRoute.name_en}</h4>
                                )}
                            </div>
                        </div>
                        <div className='route_info_blocks'>
                            <div className='route_info_block route_info_range'>{selectedRoute.distance} <span className='route_info_small'>{t('km')}</span></div>
                            <div className='route_info_block route_info_ages'>
                                {selectedRoute.age === 'no' ? null : null}
                                {selectedRoute.age === 'un' ? `${t('until')} 18` : null}
                                {selectedRoute.age === 'up' ? `${t('from')} 18` : null}
                                {selectedRoute.sex === 'A' ?
                                    (
                                        <div className='route_info_sex'>
                                            <Female fill={'#0912DB'} width={'15px'} />
                                            <Male fill={'#0912DB'} width={'15px'} />
                                        </div>
                                    ) : null}
                                {selectedRoute.sex === 'M' ?
                                    (
                                        <div className='route_info_sex'>
                                            <Male fill={'#0912DB'} width={'15px'} />
                                        </div>
                                    ) : null}

                                {selectedRoute.sex === 'W' ?
                                    (
                                        <div className='route_info_sex'>
                                            <Female fill={'#0912DB'} width={'15px'} />
                                        </div>
                                    ) : null}
                            </div>
                            <div className='route_info_block route_info_time'>{selectedRoute.time_sp} <span className='route_info_small'>{t('min')}</span></div>
                        </div>
                    </div>
                    <div className="yandex_block">
                        <YandexMap mapHtml={selectedRoute.map_block} mapRef={mapRef} />
                    </div>
                    <div ref={bottomRef} className='route_bottom'>
                        {selectedRoute.registered_users.length !== 0 ? (
                            <div className='route_travelers'>
                                <h4 style={{ paddingBottom: '10px' }}>{t('participants')} ({selectedRoute.registered_users.length}/{selectedRoute.max_user}): </h4>
                                <div className="friends_block" id="horizontal-scroller" style={{ paddingBottom: '0' }}>
                                    <div className="friends">
                                        {selectedRoute.registered_users.map(item => (
                                            <Link to={`/profile/${item.user.tg_id}`} key={item.user.tg_id}>
                                                <div className="friend" id="#" style={{ width: '40px', height: '60px' }}>
                                                    {item.user.photo_low !== null ? (
                                                        <img className="friend_avatar" src={item.user.photo_low} alt="avatar" style={{ width: '40px', height: '40px' }}></img>
                                                    ) : (
                                                        <img className="friend_avatar" src={account} alt="avatar" style={{ width: '40px', height: '40px' }}></img>
                                                    )}
                                                    <div className="friend_level" style={{ width: '30px', top: '-10px', left: '13%' }}>{item.user.level}</div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (null)}
                        {!end ?
                            <div className="footer_nav">

                                {fullRoute && !registrTrip && (
                                    <div className='route_button_chat'>
                                        <button className='button_full'>{t('filled')}</button>
                                        <p className='route_register_text'>{t('filledtext')}</p>
                                    </div>
                                )}

                                {registr && !registrTrip && !fullRoute && (
                                    <div className='route_button_chat'>
                                        <button className='button_chat' onClick={() => handleRegistrTrip(selectedRoute)}>{t('join')}</button>
                                        <p className='route_register_text'>{t('jointext')}</p>
                                    </div>
                                )}
                                {!registr && !registrTrip && !fullRoute && (
                                    <div className='route_button_chat'>
                                        <button className='button_registr' onClick={handleRegistrButton}>{t('fill')}</button>
                                        <p className='route_register_text'>{t('filltext')}</p>
                                    </div>
                                )}

                                {registrTrip && (
                                    <div className='route_button_chat'>
                                        <button className='button_leave_trip' onClick={() => handleLeaveButton(selectedRoute)}>{t('refuse')}</button>
                                        <button className='button_chat' onClick={() => handleJoinButton(selectedRoute)}>{t('open')}</button>
                                    </div>

                                )
                                }

                                <div onClick={() => copyTripLink(selectedRoute)} className='route_share'>
                                    <img src={share} alt='share' />
                                    {t('share')}
                                </div>
                            </div>
                            :
                            null}
                    </div>
                </>
            )}
            {chat && (
                <TripChat route={chat} handleCloseChat={handleCloseChat} t={t}/>
            )}
        </div>
    )
}

function YandexMap({ mapHtml, mapRef }) {
    const [html, setHtml] = useState("");

    useEffect(() => {
        setHtml(mapHtml);
    }, [mapHtml]);

    return (
        <div ref={mapRef} className="yandex_map">
            <div className="yandex_map" dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
    );
}
