import React, { useEffect, useRef } from "react";
import { default as download } from '../../img/load.svg';
import './PreLoader.css';
import { loadTelegramWebApp } from "../telegram/telegram";

export default function PreLoader({ lang }) {
  const parrentRef = useRef();
  useEffect(() => {

    loadTelegramWebApp().then(() => {
      if (window.Telegram && window.Telegram.WebApp) {
        const webApp = window.Telegram.WebApp;
        let rectParrent = parrentRef.current.getBoundingClientRect();
        parrentRef.current.style.height = webApp.viewportStableHeight - rectParrent.y + "px";
      }

    })
  }, []);
  const phrases = [
    ["We are looking for new routes for you", <br></br>, "It may take some time"],
    ["The more routes you go through,", <br></br>, "the higher your level"],
    ["Приглашайте друзей на интересные вам маршруты,", <br></br>, "and make new ones during the walk"]
  ];

  const phrases_ru = [
    ["Ищем для вас новые маршруты", <br></br>, "Это может занять какое-то время"],
    ["Чем больше маршрутов вы проходите,", <br></br>, "тем выше ваш уровень"],
    ["Приглашайте друзей на интересные вам маршруты,", <br></br>, "и заводите новых во время прогулки"]
];
  let loadText = Math.floor(Math.random() * 3);

  return (
    <div id='loaderScreen' className='loaderScreen' ref={parrentRef}>
      <img src={download} className="loader" alt="" />
      <p className="loadText">{lang === 'en' ? phrases[loadText] : phrases_ru[loadText]}</p>
    </div>
  )

}