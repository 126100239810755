import React, { useEffect } from 'react';
import './HelloScreen.css';
import { loadTelegramWebApp } from '../telegram/telegram';

export default function HelloScreen() {
  useEffect(() => {
    loadTelegramWebApp().then(() => {
      if (window.Telegram && window.Telegram.WebApp) {
        const webApp = window.Telegram.WebApp;
        document.body.style.zoom = 1.001;
        setTimeout(() => {
          document.body.style.zoom = 1;
        }, 1000);
      }
    })
  }, []);

  return (
    <div className="helloScreen" >
      <svg width="226" height="48" viewBox="0 0 226 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      </svg>
    </div>
  );
}
