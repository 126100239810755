import {Fragment, useState, useEffect} from "react";
import Home from "../appHome/Home";
import Start from "../appStart/Start";

function Main({ setRate, register, closeStart, style, closeGlass, t }) {

  return (
    <Fragment>
      <Start register={register} closeStart={closeStart} t={t}/>
      <Home register={register} closeStart={closeStart} style={style} closeGlass={closeGlass} setRate={setRate} t={t}/>
    </Fragment>
  );
}

export default Main;