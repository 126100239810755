import React, { useEffect, useRef } from "react";
import '../appRules/Rules.css';
import { t } from "i18next";

export default function Rules({ t }) {
    const bodyRef = useRef();

    useEffect(() => {
        let rectBody = bodyRef.current.getBoundingClientRect();
        bodyRef.current.style.height = window.innerHeight - rectBody.y + "px";
    }, []);
    return (
        <div ref={bodyRef} className="rules_body">
            <h1>{t('instruction')}</h1>
            <div className="points">
                <div className="point">
                    <div className="ellips">1.</div>
                    <p className="rule">
                    {t('instruction1')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">2.</div>
                    <p className="rule">
                    {t('instruction2')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">3.</div>
                    <p className="rule">
                    {t('instruction3')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">4.</div>
                    <p className="rule">
                    {t('instruction4')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">5.</div>
                    <p className="rule">
                    {t('instruction5')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">6.</div>
                    <p className="rule">
                    {t('instruction6')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">7.</div>
                    <p className="rule">
                    {t('instruction7')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">8.</div>
                    <p className="rule">
                    {t('instruction8')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">9.</div>
                    <p className="rule">
                    {t('instruction9')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">10.</div>
                    <p className="rule">
                    {t('instruction10')}
                    </p>
                </div>
            </div>
            <p className="update_page">{t('updated')}: 09.07.2024 16:30</p>
        </div>
    )
}