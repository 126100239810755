import { Link } from 'react-router-dom';
import './Start.css'
import question from '../../img/question.svg'
import rules from '../../img/rules.svg'
import manual from '../../img/manual.svg'
import smartphone from '../../img/smartphone.svg'

const Start = ({ style, register, closeStart, t }) => {
    return (
        <div className="start_body" style={style}>
            <div className="start_menu">
                <Link to='/question' className="no-underline">
                    <div className="start_btn_question">
                        <img src={question} alt='' />
                        <p>{t('frequent')}</p>
                    </div>
                </Link>
                <Link to='/rules' className="no-underline">
                    <div className={register !== undefined && !register && !closeStart ? "start_btn_rules" : ""}>
                        {register !== undefined && !register && !closeStart ? <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {/* Фильтр для создания белой тени */}
                            <defs>
                                <filter id="white-glow" x="0" y="0" width="100%" height="100%">
                                <feFlood result="flood" flood-color="white" flood-opacity="0.3"></feFlood>
                                <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"></feComposite>
                                <feMorphology in="mask" result="dilated" operator="dilate" radius="1"></feMorphology>
                                <feGaussianBlur in="dilated" result="blurred" stdDeviation="1"></feGaussianBlur>
                                <feMerge>
                                    <feMergeNode in="blurred"></feMergeNode>
                                    <feMergeNode in="SourceGraphic"></feMergeNode>
                                </feMerge>
                                </filter>
                            </defs>

                            {/* Пути с белой тенью */}
                            <g filter="url(#white-glow)">
                                <path d="M3.41699 26.25V29.1667C3.41699 32.3831 6.03387 35 9.25033 35H26.7503C29.9668 35 32.5837 32.3831 32.5837 29.1667V5.83333C32.5837 2.61688 29.9668 0 26.7503 0H9.25033C6.03387 0 3.41699 2.61688 3.41699 5.83333V8.75H6.33366V26.25H3.41699ZM9.25033 32.0833C7.64174 32.0833 6.33366 30.7752 6.33366 29.1667H20.917C20.917 29.4066 20.9359 29.6413 20.9643 29.8734C20.9732 29.9432 20.9857 30.0119 20.9964 30.081C21.0227 30.248 21.0562 30.4121 21.0964 30.5741C21.1139 30.6432 21.1299 30.7126 21.1495 30.7809C21.2057 30.9764 21.2712 31.1676 21.3464 31.3535C21.356 31.3766 21.3624 31.4008 21.3727 31.4243C21.4596 31.6305 21.5607 31.8288 21.6697 32.0221C21.6803 32.0417 21.6885 32.063 21.6996 32.0826H9.25033V32.0833ZM9.25033 2.91667H21.6996C21.6885 2.93625 21.6803 2.95761 21.6697 2.97719C21.56 3.17052 21.4596 3.36884 21.3727 3.57498C21.3631 3.59848 21.356 3.62233 21.3464 3.64583C21.2705 3.83169 21.2057 4.02359 21.1495 4.21834C21.1299 4.2867 21.1139 4.35613 21.0964 4.4252C21.0562 4.5872 21.0227 4.75133 20.9964 4.91831C20.9857 4.98739 20.9732 5.0561 20.9643 5.12589C20.9359 5.35873 20.917 5.59336 20.917 5.83333H6.33366C6.33366 4.22475 7.64174 2.91667 9.25033 2.91667ZM9.25033 8.75H23.8337V5.83333C23.8337 4.22475 25.1417 2.91667 26.7503 2.91667C28.3589 2.91667 29.667 4.22475 29.667 5.83333V29.1667C29.667 30.7752 28.3589 32.0833 26.7503 32.0833C25.1417 32.0833 23.8337 30.7752 23.8337 29.1667V26.25H9.25033V8.75Z" fill="white"/>
                                <path d="M12.167 13.125H23.8337V16.0417H12.167V13.125Z" fill="white"/>
                                <path d="M12.167 18.9583H20.917V21.8749H12.167V18.9583Z" fill="white"/>
                            </g>
                        </svg> :
                        <img src={rules} alt='rules' />
                        }
                        <p className={register !== undefined && !register && !closeStart ? "ruleShadow" : ""}>{t('rules')}</p>
                    </div>
                </Link>
                <Link to='/instruction' className="no-underline">
                    <div className="start_btn_instruction">
                        <img src={manual} alt='' />
                        <p>{t('instruction')}</p>
                    </div>
                </Link>
                <Link to='/about' className="no-underline">
                    <div className="start_btn_info">
                        <img src={smartphone} alt='' />
                        <p>{t('about')}</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Start;