import React, { useEffect, useRef } from "react";
import './Rules.css';

export default function Rules({ t }) {
    const bodyRef = useRef();

    useEffect(() => {
        let rectBody = bodyRef.current.getBoundingClientRect();
        bodyRef.current.style.height = window.innerHeight - rectBody.y + "px";
    }, []);

    return (
        <div ref={bodyRef} className="rules_body">
            <h1>{t('rules')}</h1>
            <div className="points">
                <div className="point">
                    <div className="ellips">1.</div>
                    <p className="rule">
                    {t('rule1')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">2.</div>
                    <p className="rule">
                    {t('rule2')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">3.</div>
                    <p className="rule">
                    {t('rule3')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">4.</div>
                    <p className="rule">
                    {t('rule4')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">5.</div>
                    <p className="rule">
                    {t('rule5')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">6.</div>
                    <p className="rule">
                    {t('rule6')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">7.</div>
                    <p className="rule">
                    {t('rule7')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">8.</div>
                    <p className="rule">
                    {t('rule8')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">9.</div>
                    <p className="rule">
                    {t('rule9')}
                    </p>
                </div>
                <div className="point">
                    <div className="ellips">10.</div>
                    <p className="rule">
                    {t('rule10')}
                    </p>
                </div>
                <p className="enjoy">{t('nice')}</p>
            </div>
            <p className="update_page">{t('updated')}: 09.07.2024 16:30</p>
        </div>
    )
}