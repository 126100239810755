import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import debounce from 'lodash.debounce';
import account from "../../img/account.svg";
import back from "../../img/back.svg";

export default function TripChat({ route, handleCloseChat, t }) {
    const lang = localStorage.getItem('lang');

    const headerSmallRef = useRef(null);
    const chatRef = useRef(null);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const user_info = JSON.parse(localStorage.getItem('user_info'));

    const updateChatHeight = () => {
        if (headerSmallRef.current && chatRef.current) {
            const rectHeader = headerSmallRef.current.getBoundingClientRect();
            chatRef.current.style.height = window.innerHeight - rectHeader.bottom - window.scrollY - 100 + "px";
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        updateChatHeight(); // Initial calculation

        const handleResize = debounce(() => {
            setTimeout(updateChatHeight, 300);
        }, 100);

        window.addEventListener("resize", handleResize);

        if (inputRef.current) {
            inputRef.current.addEventListener("focus", handleResize);
            inputRef.current.addEventListener("blur", handleResize);
        }
        fetchMessages()
        return () => {
            window.removeEventListener("resize", handleResize);

            if (inputRef.current) {
                inputRef.current.removeEventListener("focus", handleResize);
                inputRef.current.removeEventListener("blur", handleResize);
            }
        };
    }, []);

    const fetchMessages = async () => {
        try {
            const response = await fetch("api/messages?trip=" + route.id + "&user=" + user_info.tg_id);
            const newMessages = await response.json();
            setMessages(newMessages);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching messages:", error);
            setLoading(true);
        }
    };

    useEffect(() => {
        const interval = setInterval(fetchMessages, 2000);
        return () => clearInterval(interval);
    }, []);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSendMessage = async () => {
        if (inputValue.trim() === "") return;
        setInputValue("");
        const newMessage = { user_id: user_info.tg_id, text: inputValue, trip_id: route.id };

        try {
            const response = await fetch("api/messages", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(newMessage)
            });

            if (response.ok) {
                const savedMessage = await response.json();
                setMessages(savedMessage);
                setInputValue("");
            } else {
                console.error("Error sending message:", response.statusText);
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }

    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSendMessage();
        }
    };

    return (
        <div className="chat_block">
            <div ref={headerSmallRef} className='route_header header_bottom'>
                <div className='route_close'>
                    <button className="close_button" onClick={handleCloseChat}>
                        <img src={back} alt='' />
                    </button>
                </div>
                <div className="route_info_content">
                    <p className='route_info_id'>{t('route')} №{route.id}</p>
                    <p className='route_info_date'>{route.date}</p>
                    {lang === 'ru' ? (
                        <h4>{route.name}</h4>
                    ) : (
                        <h4>{route.name_en}</h4>
                    )}
                </div>
            </div>
            <div ref={chatRef} className={`chat_messages ${messages.length === 0 && !loading ? "chat_support_block" : ""}`}>
                {loading && (
                    <div className='server_wait'>
                        <svg width="50" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M45.5625 10.8051C40.8917 4.0378 33.1993 -0.0011736 24.9834 2.558e-07C11.1705 0.0149995 -0.0149653 11.239 1.50294e-05 25.0694C0.0139533 37.9493 9.78348 48.7183 22.5867 49.9666L18.3515 54.2061C17.9375 54.6065 17.926 55.267 18.3259 55.6815C18.7258 56.096 19.3855 56.1074 19.7995 55.707C19.8082 55.6987 19.8167 55.6901 19.825 55.6815L25.7192 49.7798C25.8165 49.6827 25.8933 49.567 25.9453 49.4397C26.0507 49.1844 26.0507 48.8978 25.9453 48.6425C25.8933 48.5152 25.8165 48.3995 25.7192 48.3023L19.825 42.4007C19.411 42.0003 18.7512 42.0118 18.3515 42.4263C17.9614 42.8306 17.9614 43.4717 18.3515 43.8761L22.3115 47.8411C9.74388 46.3595 0.755545 34.9573 2.23534 22.3738C3.71514 9.79035 15.1029 0.790527 27.6705 2.27232C40.2381 3.75412 49.2266 15.1562 47.7467 27.7396C46.9771 34.2842 43.434 40.1812 38.0203 43.9283C37.5395 44.245 37.4062 44.892 37.7225 45.3734C38.0389 45.8549 38.685 45.9883 39.1658 45.6716C39.1795 45.6626 39.1929 45.6532 39.2062 45.6437C50.5697 37.7807 53.4155 22.183 45.5625 10.8051Z" fill="#523DD8" />
                        </svg>
                    </div>
                )}
                {messages.length === 0 && !loading ? (
                    <div className="chat_message_support">
                        <p>{t('empty')}</p>
                        <p>{t('submit')}</p>
                    </div>
                ) : (<></>)}
                {!loading && messages.map((msg, index) => (
                    <div key={index} className={`chat_message ${msg.class}`}>
                        <div className="chat_user_photo">
                            <Link to={`/profile/${msg.user_id}`} key={msg.user_id}>
                                {msg.photo_low !== null ? (
                                    <img className="chat_user_photo" src={msg.photo_low} alt="avatar" ></img>
                                ) : (
                                    <img className="chat_user_photo" src={account} alt="avatar" ></img>
                                )}
                            </Link>
                        </div>
                        <div className={`chat_user_message ${msg.class}`}>
                            <span>{msg.user}</span>
                            <p>{msg.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="chat_footer">
                <div className="chat_input">
                    <input
                        type="text"
                        placeholder={t('placeholder')}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown} // Add onKeyDown handler
                        maxLength={200}
                        ref={inputRef} // Adding ref to input element
                    />
                </div>
                <div className="chat_send" onClick={handleSendMessage}>
                    <svg width="30" height="18" viewBox="0 0 50 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M47.4098 18.1514H20.639M3 8.15145H9.25M5.5 18.1514H9.25M6.75 28.1514H9.25M20.4038 19.3754L18.175 26.7729C17.3313 29.5734 16.9094 30.9736 17.2684 31.8096C17.58 32.5356 18.2164 33.0716 18.9848 33.2551C19.8698 33.4666 21.1778 32.8126 23.7938 31.5046L43.3445 21.7291C45.6475 20.5776 46.7992 20.0019 47.1687 19.2226C47.4902 18.5446 47.4902 17.7581 47.1687 17.0801C46.7992 16.3009 45.6475 15.7251 43.3445 14.5736L23.767 4.78492C21.1582 3.48057 19.8539 2.8284 18.9697 3.03892C18.202 3.22175 17.5657 3.75632 17.2532 4.48097C16.8933 5.3156 17.3107 6.7129 18.1455 9.50752L20.4065 17.0766C20.5343 17.5041 20.598 17.7179 20.6233 17.9356C20.6458 18.1289 20.6455 18.3239 20.6225 18.5171C20.5967 18.7349 20.5325 18.9484 20.4038 19.3754Z" stroke="#0912DB" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
        </div>
    );
}
