import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import BackButton from '../BackButton.js'
import './Profile.css';
import '../appTrip/Trip.css';
import account from '../../img/account.svg';
import pencil from '../../img/pencil.svg';
import camera from '../../img/camera.svg';
import { ReactComponent as Male } from '../../img/sex_male.svg';
import { ReactComponent as Female } from '../../img/sex_female.svg';
import { useTranslation } from 'react-i18next';

// Загрузка файла
function FileUploader({ onFileSelect }) {
    const fileInputRef = useRef(null);

    const handleFileUpload = (event) => {
        const user_info = JSON.parse(localStorage.getItem('user_info'));
        let file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const data = {
                'user_id': user_info.tg_id,
                'file': reader.result
            }
            fetch('api/photo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    localStorage.setItem('user_photo', JSON.stringify(data));
                    window.location.reload()
                })
                .catch(error => {

                });
        };
        console.log('Выбранный файл:', file);
        onFileSelect(file);
    };

    const fileButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        // <form method="post" encType="multipart/form-data">
        <div>
            <input
                type="file"
                name="myVacationPhoto"
                accept="image/*"
                onChange={handleFileUpload}
                ref={fileInputRef}
                style={{ display: 'none' }} // Скрыть input
            />
            <button className="camera" onClick={fileButtonClick}>
                <img src={camera} alt="Загрузить фото" />
            </button>
        </div>
        // </form>
    );
}

// Показ файла
function FileDisplay({ file, defaultImage }) {
    return (
        <img
            className="avatar"
            src={file ? file : defaultImage}
            alt="avatar"
            style={{
                width: '100%',
            }}
        />
    );
}

export default function Profile() {
    const { t } = useTranslation();
    let user_photo = JSON.parse(localStorage.getItem('user_photo'));

    const getAge = (birthday) => {
        const today = new Date();
        const diff = today - birthday;
        const ageDate = new Date(diff);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const user_info = JSON.parse(localStorage.getItem('user_info'));
    let day = 1;
    let month = 1;
    let year = 2024;
    let remain_dist = user_info.level.exp - user_info.exp;
    let age = null;
    let sex = false;
    let maleOpac = '50%';
    let femaleOpac = '50%';
    if (user_info.birthday) {
        let year_month_day = user_info.birthday.split('-');
        year = parseInt(year_month_day[0]);
        month = parseInt(year_month_day[1]);
        day = parseInt(year_month_day[2]);
        let birthday = new Date(year, month - 1, day);
        age = getAge(birthday)
        if (user_info.sex === "M") {
            sex = 'male';
            maleOpac = '100%';
        } else {
            sex = 'female';
            femaleOpac = '100%'
        }
    }

    const inputRef = useRef(null);
    const buttonRef = useRef(null);

    const location = useLocation();
    const editButton = [
        t('fillancet'),
        t('edit')
    ];
    const [edit, setEdit] = useState(0);
    const [selectedRoute, setSelectedRoute] = useState(true);
    const [style, setStyle] = useState({ display: 'none' });
    const [name, setName] = useState(user_info.name);
    const [inputValue, setInputValue] = useState(user_info.name);
    const [selectedDay, setSelectedDay] = useState(day);
    const [selectedMonth, setSelectedMonth] = useState(month);
    const [selectedYear, setSelectedYear] = useState(year);
    const [isDataСorrect, setIsDataСorrect] = useState(true);
    const [fullYears, setFullYears] = useState(age);
    const [styleAgeAndSex, setStyleAgeAndSex] = useState({ display: 'none' });
    const [selectedSex, setSelectedSex] = useState(sex);
    const [maleOpacity, setMaleOpacity] = useState(maleOpac);
    const [femaleOpacity, setFemaleOpacity] = useState(femaleOpac);
    const [selectedFile, setSelectedFile] = useState(null);
    const [upDate, setUpDate] = useState(false);
    const [saveInfo, setSaveInfo] = useState(false);
    const [days, setDays] = useState([]);
    const maxNameLength = 25;
    const [accept, setAccept] = useState({
        first: false,
        second: false
    })

    useEffect(() => {
        if (user_info.birthday === null) {
            setEdit(0)
        } else {
            setEdit(1)
        }
    })

    const handleAcceptClick = (key) => {
        setAccept((prevState) => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    // Заменить на данные с бэка
    const friends_count = user_info.friends ? user_info.friends.length : 0;

    // Для передачи файла с загрузки 
    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    // Не ебу мне это надо или нет, спиздил у тебя Никит для высоты блока
    const childrenRef = useRef(null);
    useEffect(() => {
        if (!childrenRef.current) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    let rectChildren = childrenRef.current.getBoundingClientRect();
                    childrenRef.current.style.height = window.innerHeight - rectChildren.y + "px";
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 1.0,
            }
        );
        const currentRef = childrenRef.current;
        observer.observe(childrenRef.current);

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [selectedRoute]);

    // Определение значения для изменения input
    const handleInputChange = (e) => {
        const { value } = e.target;
        if (value.length <= maxNameLength) {
            setInputValue(e.target.value);
            setUpDate(false);
        } else {
            alert(t('big'));
        }
        // setIsKeyboardVisible(true);
    };

    //Добавление имени
    useEffect(() => {
        if (name === '') {
            setStyle({ display: 'none' });
        } else {
            setStyle({ display: 'block' });
        }
    }, [name]);

    // Корректная дата рождения и обязательный выбор для даты и пола

    useEffect(() => {
        if (fullYears === null) {
            setStyleAgeAndSex({ display: 'none' });
        } else {
            setStyleAgeAndSex({ display: 'flex' });
        }
    }, [fullYears]);

    // const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);

    const handleDayChange = (e) => {
        setIsKeyboardVisible(false);
        setSelectedDay(e.target.value);
        validateDate(selectedMonth, e.target.value, selectedYear);
        setUpDate(false);
        document.body.style.zoom = 1;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleMonthChange = (e) => {
        setIsKeyboardVisible(false);
        setSelectedMonth(e.target.value);
        validateDate(e.target.value, selectedDay, selectedYear);
        setUpDate(false);
        document.body.style.zoom = 1;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleYearChange = (e) => {
        setIsKeyboardVisible(false);
        setSelectedYear(e.target.value);
        validateDate(selectedMonth, selectedDay, e.target.value);
        setUpDate(false);
        document.body.style.zoom = 1;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const validateDate = (month, day, year) => {
        const currentDate = new Date();
        const selectedDate = new Date(year, month - 1, day);
        if (selectedDate > currentDate || getAge(selectedDate) > 120) {
            setIsDataСorrect(false);
        } else {
            setIsDataСorrect(true);
            setFullYears(getAge(selectedDate));
        }
    };

    const handleButtonClick = () => {
        if (selectedRoute) {
            setSelectedRoute(false)
        }
    };

    const handleButtonClickSave = () => {
        if (!saveInfo) {
            if (!selectedRoute) {
                validateDate(selectedMonth, selectedDay, selectedYear);
                if (!(isDataСorrect && selectedSex)) {
                    alert(t('correct'));
                    return
                } else {
                    setIsDataСorrect(true);
                    setUpDate(true);
                }
            }

            setName(inputValue);
            const data = {
                user_id: user_info.tg_id,
                name: inputValue,
                birthday: `${selectedYear}-${selectedMonth}-${selectedDay}`,
                sex: selectedSex,
            };
            console.log(data);
            fetch('api/profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    localStorage.setItem('user_info', JSON.stringify(data));
                    localStorage.setItem('trips', JSON.stringify([]));
                    setSaveInfo(true);
                    if (selectedRoute) {
                        setSelectedRoute(false)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    // Выбор пола в редактировании профиля и передача его значения

    const handleSexClick = (sex) => {
        setIsKeyboardVisible(false);
        setSelectedSex(sex);
        setUpDate(false);
        if (sex === 'male') {
            setMaleOpacity('100%');
            setFemaleOpacity('50%');
        } else if (sex === 'female') {
            setMaleOpacity('50%');
            setFemaleOpacity('100%');
        } else if (sex === null) {

        }
    };

    const handleFocus = (sex) => {
        if (sex === 'male') {
            setMaleOpacity('100%');
        } else {
            setFemaleOpacity('100%');
        }
    };

    const handleBlur = (sex) => {
        if (sex === 'male' && selectedSex !== 'male') {
            setMaleOpacity('50%');
        } else if (sex === 'female' && selectedSex !== 'female') {
            setFemaleOpacity('50%');
        }
    };

    // useEffect(() => {
    //     if (selectedRoute) {
    //         document.getElementById("horizontal-scroller").addEventListener('wheel', function (event) {
    //             if (event.deltaMode === event.DOM_DELTA_PIXEL) {
    //                 var modifier = 1;
    //                 // иные режимы возможны в Firefox
    //             } else if (event.deltaMode === event.DOM_DELTA_LINE) {
    //                 var modifier = parseInt(getComputedStyle(this).lineHeight);
    //             } else if (event.deltaMode === event.DOM_DELTA_PAGE) {
    //                 var modifier = this.clientHeight;
    //             }
    //         })
    //     };
    // }, [selectedRoute]);

    useEffect(() => {
        updateDays(selectedYear, selectedMonth);
    }, [selectedYear, selectedMonth]);

    const updateDays = (year, month) => {
        const daysInMonth = new Date(year, month, 0).getDate();
        const newDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        setDays(newDays);
    };

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            const windowHeight = window.innerHeight;
            const containerHeight = containerRef.current.clientHeight;
            const remainingHeight = windowHeight - containerHeight;
            containerRef.current.style.height = `${remainingHeight}px`;
        }
    }, []);

    // useEffect(() => {
    //     if (inputRef.current && buttonRef.current) {
    //         let rectInput = inputRef.current.getBoundingClientRect();
    //         let rectButton = buttonRef.current.getBoundingClientRect();
    //         console.log(rectInput);
    //         console.log(rectButton);
    //         buttonRef.current.style.position = 'relative';
    //         let height = window.innerHeight;
    //         let padding = height - rectInput.bottom - 140;
    //         console.log(height);
    //         console.log(padding);
    //         buttonRef.current.style.marginTop = padding + 'px';
    //     }

    // });
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const isKeyboardPresent = window.innerHeight <= window.outerHeight;
            let text = window.innerHeight + ' - ' + window.outerHeight;
            // alert(text);
            setIsKeyboardVisible(isKeyboardPresent);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });


    return (
        <div className="profile">
            <div className="preview">
                <div className="ton"></div>
                {user_photo && user_photo.photo ?
                    <div className="photo_block">
                        <FileDisplay file={user_photo.photo} />
                    </div> :
                    null
                }
                {selectedRoute ?
                    <div className="ageAndSex" style={styleAgeAndSex}>
                        <p className="age">
                            {fullYears}
                        </p>
                        {selectedSex && (
                            <div className="sex">
                                {selectedSex === 'male' ? (
                                    <Male fill={'#0912DB'} style={{ opacity: '100%' }} />
                                ) : (
                                    <Female fill={'#0912DB'} style={{ opacity: '100%' }} />
                                )}
                            </div>
                        )}
                    </div>
                    :
                    <FileUploader onFileSelect={handleFileSelect} />
                }
            </div>
            {selectedRoute ?
                <div className="profile_body">
                    <div className="scroll_container">
                        <div className="name_block">
                            <h1 id="profileName" className="profile_name" style={style}>{name}</h1>
                            <p className="telegram_username">@{user_info.user_name}</p>
                        </div>
                        <div className='profile_route_info_blocks'>
                            <div className='route_info_block_profile route_info_range'>{user_info.trip_count} <span className='route_info_small'>{t('sum')}</span></div>
                            <div className='route_info_block_profile route_info_ages'>{user_info.distance} <span className='route_info_small span_small'>{t('km')}</span></div>
                            <div className='route_info_block_profile route_info_time'>{user_info.total_time_sp} <span className='route_info_small'>{t('h')}</span></div>
                        </div>
                        <div className='profile_stat'>
                            <div className='home_level'>{user_info.level.id} {t('level')}</div>
                            <div className='home_done'>
                                <div className='home_distanse_user'>{user_info.exp} xp</div>
                                <div className='home_distanse_need'>{t('still')} {remain_dist} xp</div>
                            </div>
                            <div className='home_progress'>
                                <progress className='home_progress' value={user_info.exp} max={user_info.level.exp} />
                            </div>
                        </div>
                        <p className="friends_text">{t('invited')} <span>({friends_count})</span>:</p>
                        <div className="friends_block">
                            <div className="friends">
                                {user_info.friends ? user_info.friends.map(item => (
                                    <Link to={`/profile/${item.tg_id}`} key={item.tg_id}>
                                        <div className="friend" id="#">
                                            {item.photo_low ? (
                                                <img className="friend_avatar" src={item.photo_low} alt="avatar" />
                                            ) : (
                                                <img className="friend_avatar" src={account} alt="avatar" />
                                            )}
                                            <div className="friend_level">{item.level}</div>
                                        </div>
                                    </Link>
                                )) : null}
                            </div>
                        </div>
                    </div>
                    {!(location.pathname !== '/profile') && location.pathname.startsWith('/profile') ?
                        <button className="edit_button" onClick={handleButtonClick}>
                            {editButton[edit]}
                            <img src={pencil} alt="edit"></img>
                        </button>
                        :
                        null
                    }
                    <div className="stop_ham"></div>
                </div>
                :
                <div className="profile_body">
                    <div className="scroll_container" style={{ height: '100%' }}>
                        <div className="telegram">
                            <BackButton setSelectedRoute={setSelectedRoute} />
                            <p className="telegram_username">@{user_info.user_name}</p>
                        </div>

                        <div ref={inputRef} className="input_block">
                            <div className="inputs">
                                <p className="input_name">{t('name')}:</p>
                                <input
                                    type="text"
                                    placeholder={t('nameplaceholder')}
                                    className="you_name"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    maxLength={maxNameLength}
                                />
                                <p className="input_name">{t('datebirth')}*:</p>
                                <div className='filter_date_trip_profile'>

                                    <select
                                        className='filter_date_select_profile'
                                        onChange={handleDayChange}
                                        value={selectedDay}
                                        style={{ backgroundColor: isDataСorrect ? '#FFFFFF' : '#FFF4F4' }}
                                    >
                                        {days && days.map(day => (
                                            <option key={day} value={day}>{day}</option>
                                        ))}

                                    </select>
                                    <select
                                        className='filter_date_select_profile'
                                        onChange={handleMonthChange}
                                        value={selectedMonth}
                                        style={{ backgroundColor: isDataСorrect ? '#FFFFFF' : '#FFF4F4' }}
                                    >
                                        <option value={1}>{t('january')}</option>
                                        <option value={2}>{t('february')}</option>
                                        <option value={3}>{t('march')}</option>
                                        <option value={4}>{t('april')}</option>
                                        <option value={5}>{t('may')}</option>
                                        <option value={6}>{t('june')}</option>
                                        <option value={7}>{t('july')}</option>
                                        <option value={8}>{t('Август')}</option>
                                        <option value={9}>{t('Сентябрь')}</option>
                                        <option value={10}>{t('Октябрь')}</option>
                                        <option value={11}>{t('Ноябрь')}</option>
                                        <option value={12}>{t('Декабрь')}</option>
                                    </select>
                                    <select
                                        className='filter_date_select_profile'
                                        name='filter_year'
                                        onChange={handleYearChange}
                                        value={selectedYear}
                                        style={{ backgroundColor: isDataСorrect ? '#FFFFFF' : '#FFF4F4' }}
                                    >
                                        {years && years.map(item => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <p className="input_name">{t('gender')}*:</p>
                                <div className="change_sex">
                                    <button className="button_sex active"
                                        onClick={() => handleSexClick('male')}
                                        onFocus={() => handleFocus('male')}
                                        onBlur={() => handleBlur('male')}
                                    >
                                        <Male fill={'#523DD8'} style={{ opacity: maleOpacity }} />
                                    </button>
                                    <button className="button_sex"
                                        onClick={() => handleSexClick('female')}
                                        onFocus={() => handleFocus('female')}
                                        onBlur={() => handleBlur('female')}
                                    >
                                        <Female fill={'#523DD8'} style={{ opacity: femaleOpacity }} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p className="ps">* - {t('important')}</p>
                        {!isKeyboardVisible && (
                            <div ref={buttonRef} className='bottom_button'>
                                <div className="update_button" style={{ display: upDate ? 'flex' : 'none' }}>{t('dataupdated')}</div>
                                <button className="save_profile_button" disabled={user_info.birthday === null ? (accept.first === false || accept.second === false) : false} style={{ backgroundColor: upDate && '#AFAFB5' }} onClick={handleButtonClickSave}>
                                    {t('save')}
                                    <img src={pencil} alt="edit"></img>
                                </button>

                                {user_info.birthday === null ?
                                    <>
                                        <div className="checkboxContainer">
                                            <button className="checkboxAccept" onClick={() => handleAcceptClick('first')}>
                                                {accept.first &&
                                                    <svg className="accept" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.4011 0.267116L4.66794 7.80579L1.59895 4.80849C1.42329 4.63746 1.18505 4.54137 0.936641 4.54137C0.688229 4.54137 0.44999 4.63746 0.274336 4.80849C0.0986816 4.97952 0 5.21149 0 5.45336C0 5.57313 0.0242272 5.69172 0.0712979 5.80237C0.118368 5.91301 0.187361 6.01355 0.274336 6.09824L4.00563 9.73133C4.09235 9.81647 4.19552 9.88404 4.3092 9.93015C4.42287 9.97626 4.5448 10 4.66794 10C4.79108 10 4.91301 9.97626 5.02668 9.93015C5.14036 9.88404 5.24353 9.81647 5.33024 9.73133L13.7257 1.55687C13.9013 1.38583 14 1.15387 14 0.911991C14 0.670116 13.9013 0.438147 13.7257 0.267116C13.55 0.0960845 13.3118 0 13.0634 0C12.8149 0 12.5767 0.0960845 12.4011 0.267116Z" fill="#523DD8" />
                                                    </svg>
                                                }
                                            </button>
                                            <p className="ps">{t('accept1')} <span>{t('accept2')}</span> {t('accept3')} <span>{t('accept4')}</span></p>
                                        </div>
                                        <div className="checkboxContainer">
                                            <button className="checkboxAccept" onClick={() => handleAcceptClick('second')}>
                                                {accept.second &&
                                                    <svg className="accept" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.4011 0.267116L4.66794 7.80579L1.59895 4.80849C1.42329 4.63746 1.18505 4.54137 0.936641 4.54137C0.688229 4.54137 0.44999 4.63746 0.274336 4.80849C0.0986816 4.97952 0 5.21149 0 5.45336C0 5.57313 0.0242272 5.69172 0.0712979 5.80237C0.118368 5.91301 0.187361 6.01355 0.274336 6.09824L4.00563 9.73133C4.09235 9.81647 4.19552 9.88404 4.3092 9.93015C4.42287 9.97626 4.5448 10 4.66794 10C4.79108 10 4.91301 9.97626 5.02668 9.93015C5.14036 9.88404 5.24353 9.81647 5.33024 9.73133L13.7257 1.55687C13.9013 1.38583 14 1.15387 14 0.911991C14 0.670116 13.9013 0.438147 13.7257 0.267116C13.55 0.0960845 13.3118 0 13.0634 0C12.8149 0 12.5767 0.0960845 12.4011 0.267116Z" fill="#523DD8" />
                                                    </svg>
                                                }
                                            </button>
                                            <p className="ps">{t('accept5')}</p>
                                        </div>
                                    </>
                                    :
                                    (!user_info.birthday === null) &&
                                    setAccept({
                                        first: true,
                                        second: true
                                    })
                                }
                            </div>)}

                    </div>
                </div>
            }
        </div>
    )
}