import React, { useRef, useEffect } from "react";
import "./Carousel.css";
import { Link } from "react-router-dom";
import { default as rocket } from "../../img/rocketInSpace.svg";
import handles from "../../img/handles.svg";
import book from "../../img/book.svg";

const Carousel = ({ t }) => {
  const parrentRef = useRef();

  useEffect(() => {
    let rectParent = parrentRef.current.getBoundingClientRect();
    parrentRef.current.style.height = window.innerHeight - rectParent.y + "px";
  }, [])
    const fs = useRef(null);
    const ss = useRef(null);
    const ts = useRef(null);

  function scrollToFirst () {
    fs.current.scrollIntoView({behavior: "smooth", inline: "center", block: "nearest"});
  }
  function scrollToSecond () {
    ss.current.scrollIntoView({behavior: "smooth", inline: "center", block: "nearest"});
  }
  function scrollToThird () {
    ts.current.scrollIntoView({behavior: "smooth", inline: "center", block: "nearest"});
  }

  // Горизонтальная прокрутка
  const userAgent = navigator.userAgent;

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      return;
    } else {
      document.getElementById("horizontal-scroller").addEventListener('wheel', function (event) {
        if (event.deltaMode === event.DOM_DELTA_PIXEL) {
            var modifier = 1;
            // иные режимы возможны в Firefox
        } else if (event.deltaMode === event.DOM_DELTA_LINE) {
            var modifier = parseInt(getComputedStyle(this).lineHeight);
        } else if (event.deltaMode === event.DOM_DELTA_PAGE) {
            var modifier = this.clientHeight;
        }

        if (event.deltaY !== 0) {
            // замена вертикальной прокрутки горизонтальной
            this.scrollLeft += modifier * event.deltaY;
            event.preventDefault();
        }

    })
    }
}, []);

  return (
    <div className="sliderBackground" ref={parrentRef}>
      <Link to='/home'>
          <button className="closeSlider"></button>
        </Link>
      <div className="slider"  id = "horizontal-scroller">
          <div className="carouselCard" ref={fs}>
            
            <img className="carouselSvg" src={rocket} alt="" />
           
            <div className="navDots">
            <button className="dot active-dot" onClick={scrollToFirst}>
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="3"
                    cy="3"
                    r="2"
                    stroke="#523DD8"
                    fill="#523DD8"
                  />
                </svg>
              </button>
              <button className="dot active-dot" onClick={scrollToSecond}>
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="3"
                    cy="3"
                    r="2"
                    stroke="#523DD8"
                    fill="#none"
                  />
                </svg>
              </button>
              <button className="dot active-dot" onClick={scrollToThird}>
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="3"
                    cy="3"
                    r="2"
                    stroke="#523DD8"
                    fill="none"
                  />
                </svg>
              </button>
           </div>
            <div className="carouselText">
              <h1 className="carouselTopText">{t('explore')}</h1>
              <p className="carouselBotText">{t('learn')}</p>
            </div>
          </div>
          <div className="carouselCard" ref={ss}>
            
              <img className="carouselSvg" src={handles} alt="" />
           
              <div className="navDots">
                <button className="dot active-dot" onClick={scrollToFirst}>
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="2"
                      stroke="#523DD8"
                      fill="none"
                    />
                  </svg>
                </button>
                <button className="dot active-dot" onClick={scrollToSecond}>
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="2"
                      stroke="#523DD8"
                      fill="#523DD8"
                    />
                  </svg>
                </button>
                <button className="dot active-dot"  onClick={scrollToThird}>
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="2"
                      stroke="#523DD8"
                      fill="none"
                    />
                  </svg>
                </button>
           </div>
            <div className="carouselText">
              <h1 className="carouselTopText">{t('makenew')}</h1>
              <p className="carouselBotText">{t('findlike')}</p>
            </div>
          </div>
          <div className="carouselCard" ref={ts}>
            
              <img className="carouselSvg" src={book} alt="" />
           
              <div className="navDots">
            <button className="dot active-dot" onClick={scrollToFirst}>
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="3"
                    cy="3"
                    r="2"
                    stroke="#523DD8"
                    fill="none"
                  />
                </svg>
              </button>
              <button className="dot active-dot"  onClick={scrollToSecond}>
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="3"
                    cy="3"
                    r="2"
                    stroke="#523DD8"
                    fill="#none"
                  />
                </svg>
              </button>
              <button className="dot active-dot"  onClick={scrollToThird}>
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="3"
                    cy="3"
                    r="2"
                    stroke="#523DD8"
                    fill="#523DD8"
                  />
                </svg>
              </button>
           </div>
            <div className="carouselText">
              <h1 className="carouselTopText">{t('improveyour')}</h1>
              <p className="carouselBotText">t{t('ithas')}</p>
            </div>
          </div>
          
      </div>
    </div>

  );
};

export default Carousel;

