import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import account from '../../img/account.svg';
import { ReactComponent as Male } from '../../img/sex_male.svg';
import { ReactComponent as Female } from '../../img/sex_female.svg';
import TripRun from '../appTrip/TripRun';

function ProfileOther({ t }) {
    const lang = localStorage.getItem('lang');

    const { userId } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [user_name, setUserName] = useState(null);
    const [friend, setFriend] = useState(null);
    const [fullYears, setFullYears] = useState(null);
    const [level, setLevel] = useState(null);
    const [stat, setStat] = useState(null);
    const [styleAgeAndSex, setStyleAgeAndSex] = useState({ display: 'none' });
    const [selectedSex, setSelectedSex] = useState("M");
    const [photo, setPhoto] = useState(null);
    const [selectedTrip, setSelectedTrip] = useState(null);

    const getAge = (birthday) => {
        let year_month_day = birthday.split('-');
        let year = parseInt(year_month_day[0]);
        let month = parseInt(year_month_day[1]);
        let day = parseInt(year_month_day[2]);
        const birthdat_date = new Date(year, month - 1, day);
        const today = new Date();
        const diff = today - birthdat_date;
        const ageDate = new Date(diff);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    useEffect(() => {
        const user_info = JSON.parse(localStorage.getItem('user_info'));
        if (!userId) {
            navigate('/home');
            return;
        } else if (parseInt(userId) === user_info.tg_id) {
            navigate('/profile');
            return;
        }

        fetch(`/api/user?id=${userId}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setUserName(data.user_name);
                if (data.birthday !== null) {
                    let age = getAge(data.birthday);
                    setFullYears(age);
                    setFriend(data);
                    setName(data.name);
                    let level_data = {
                        level_name: data.level.name,
                        level_max: data.level.exp,
                        level_need: data.level.exp - data.exp,
                        level_dist: data.exp,
                        level_id: data.level.id
                    };
                    setLevel(level_data);
                    let stat_data = {
                        count: data.trip_count,
                        dist: data.distance,
                        time: data.total_time_sp,
                    }
                    setStat(stat_data);
                    setStyleAgeAndSex({ display: 'flex' })
                    setPhoto(data.photo);
                }

            })
            .catch(error => {
                console.error('Error fetching user data:', error);
                navigate('/home');
            });
    }, [userId, navigate]);

    const [history, setHistory] = useState([]);

    const [selectedRoute, setSelectedRoute] = useState(true);
    const [style, setStyle] = useState({ display: 'none' });
    const childrenRef = useRef(null);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const response = await fetch('/api/history?id=' + userId);
                const data = await response.json();
                setHistory(data);
            } catch (error) {
                console.error('Error fetching history:', error);
            }
        };

        fetchHistory();
    }, [userId]);

    useEffect(() => {
        if (!childrenRef.current) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    let rectChildren = childrenRef.current.getBoundingClientRect();
                    childrenRef.current.style.height = window.innerHeight - rectChildren.y + "px";
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 1.0,
            }
        );
        const currentRef = childrenRef.current;
        observer.observe(childrenRef.current);

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [selectedRoute]);

    //Добавление имени
    useEffect(() => {
        if (name === '') {
            setStyle({ display: 'none' });
        } else {
            setStyle({ display: 'block' });
        }
    }, [name]);


    // Горизонтальная прокрутка
    useEffect(() => {
        if (selectedRoute) {
            document.getElementById("horizontal-scroller").addEventListener('wheel', function (event) {
                if (event.deltaMode === event.DOM_DELTA_PIXEL) {
                    var modifier = 1;
                    // иные режимы возможны в Firefox
                } else if (event.deltaMode === event.DOM_DELTA_LINE) {
                    var modifier = parseInt(getComputedStyle(this).lineHeight);
                } else if (event.deltaMode === event.DOM_DELTA_PAGE) {
                    var modifier = this.clientHeight;
                }

                if (event.deltaY !== 0) {
                    // замена вертикальной прокрутки горизонтальной
                    this.scrollLeft += modifier * event.deltaY;
                    event.preventDefault();
                }

            })
        };
    }, [selectedRoute]);

    let user_info = JSON.parse(localStorage.getItem('user_info'));

    const handleButtonClick = (route, state) => {
        route.trip.state = state;
        setSelectedTrip(route.trip);
    };

    const handleCloseClick = () => {
        setSelectedTrip(null);
    };

    return (
        <div className="profile">
            <div className="preview">
                <div className="ton"></div>
                {photo ?
                    <div className="photo_block">
                        <img
                            className="avatar"
                            src={photo}
                            alt="avatar"
                            style={{
                                width: '100%',
                            }}

                        /></div> :
                    null
                }

                <div className="ageAndSex" style={styleAgeAndSex}>
                    <p className="age">
                        {fullYears}
                    </p>
                    {selectedSex && (
                        <div className="sex">
                            {selectedSex === 'M' ? (
                                <Male fill={'#0912DB'} style={{ opacity: '100%' }} />
                            ) : (
                                <Female fill={'#0912DB'} style={{ opacity: '100%' }} />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div ref={childrenRef} className="profile_body">
                <div className="scroll_container">
                    <div className="name_block">
                        <h1 id="profileName" className="profile_name" style={style}>{name}</h1>
                        <p className="telegram_username">@{user_name}</p>
                    </div>
                    {stat === null ? (
                        <div className='profile_route_info_blocks'>
                            <div className='route_info_block_profile route_info_range'>0 <span className='route_info_small'>{t('sum')}</span></div>
                            <div className='route_info_block_profile route_info_ages'>0 <span className='route_info_small span_small'>{t('km')}</span></div>
                            <div className='route_info_block_profile route_info_time'>0 <span className='route_info_small'>{t('h')}</span></div>
                        </div>
                    ) : (
                        <div className='profile_route_info_blocks'>
                            <div className='route_info_block_profile route_info_range'>{stat.count} <span className='route_info_small'>{t('sum')}</span></div>
                            <div className='route_info_block_profile route_info_ages'>{stat.dist} <span className='route_info_small span_small'>{t('km')}</span></div>
                            <div className='route_info_block_profile route_info_time'>{stat.time} <span className='route_info_small'>{t('h')}</span></div>
                        </div>
                    )}
                    {level === null ? (
                        <div className='profile_stat'>
                            <div className='home_level'>1 {t('level')}</div>
                            <div className='home_done'>
                                <div className='home_distanse_user'>0 xp</div>
                                <div className='home_distanse_need'>{t('still')} 0 xp</div>
                            </div>
                            <div className='home_progress'>
                                <progress className='home_progress' value={0} max={1} />
                            </div>
                        </div>
                    ) : (
                        <div className='profile_stat'>
                            <div className='home_level'>{level.level_id} {t('level')}</div>
                            <div className='home_done'>
                                <div className='home_distanse_user'>{level.level_dist} xp</div>
                                <div className='home_distanse_need'>{t('still')} {level.level_need} xp</div>
                            </div>
                            <div className='home_progress'>
                                <progress className='home_progress' value={level.level_dist} max={level.level_max} />
                            </div>
                        </div>
                    )}
                    <div className='friends_contain'>
                        <p className="friends_text">{t('invited')}</p>
                        <div className="friends_block" id="horizontal-scroller" style={{ paddingBottom: '0' }}>
                            <div className="friends">
                                {friend && friend.friends ? friend.friends.map(item => (
                                    <Link to={`/profile/${item.tg_id}`} key={item.tg_id}>
                                        <div className="friend" id="#">
                                            {item.photo_low ? (
                                                <img className="friend_avatar" src={item.photo_low} alt="avatar" />
                                            ) : (
                                                <img className="friend_avatar" src={account} alt="avatar" />
                                            )}
                                            <div className="friend_level">{item.level}</div>
                                        </div>
                                    </Link>
                                )) : null}
                            </div>
                        </div>
                    </div>
                    <div
                        className='home_history'
                        style={{
                            width: 'calc(100% - 20px)',
                            position: 'inherit',
                            padding: '0'
                        }}>
                        <h3>{t('history')}</h3>
                        <div className='home_scroll'>
                            {history.length === 0 ? (
                                <div className='home_search_result'>
                                    <h2 style={{ paddingBottom: '30px', marginBottom: '20px' }}>{t('found')}</h2>
                                </div>
                            ) : (

                                history.map(item => (
                                    <div key={item.id} className="search_result_item">
                                        <div className='result_item_left'>
                                            <span className='result_item_id'>{t('route')} №{item.trip.id}</span>
                                            <p>{item.trip.date}</p>
                                            {lang === 'ru' ? (
                                                <h4>{item.trip.name}</h4>
                                            ) : (
                                                <h4>{item.trip.name_en}</h4>
                                            )}

                                            <span className='result_item'>{item.trip.distance} {t('km')} {item.trip.time_sp} {t('min')}</span>
                                        </div>
                                        {item.state === 'W' ? (
                                            <button className='res_item_button result_about_button' style={{width: lang === 'en' && '75px'}} onClick={() => handleButtonClick(item, item.state)}>{t('more')}</button>
                                        ) : (
                                            null
                                        )}
                                        {item.state === 'E' || item.state === 'Q' || item.state === 'R' || item.state === 'Y' ? (
                                            <button className='res_item_button result_end_button' onClick={() => handleButtonClick(item, item.state)}>{t('finished')}</button>
                                        ) : (
                                            null
                                        )}

                                    </div>
                                ))

                            )}
                        </div>
                    </div>
                </div>
            </div>
            {selectedTrip && (
                <TripRun
                    selectedRoute={selectedTrip}
                    user_info={user_info}
                    handleCloseClick={handleCloseClick}
                    customClass="custom-top-style"
                    end={selectedTrip.state === 'E' ? true : undefined}
                />
            )}
        </div>
        //     </>
        // )}</>
    );
}

export default ProfileOther;
