import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useFooter } from '../appFooter/FooterContext';
import TripRun from '../appTrip/TripRun';
import './Home.css';
import union from '../../img/Union.svg';

// Функция для проверки загрузки изображений
function checkImagesLoaded() {
    const images = document.getElementsByTagName('img');
    for (let i = 0; i < images.length; i++) {
        if (!images[i].complete) {
            return false;
        }
    }
    return true;
}

function Home({ setRate, register, style, closeGlass, closeStart, t }) {
    const lang = localStorage.getItem('lang');

    const parrentRef = useRef();
    const childrenRef = useRef();
    const scrollRef = useRef();
    const { setFooterVisible } = useFooter();
    const history = JSON.parse(localStorage.getItem('user_history'))
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [registrTrip, setRegistrTrip] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const navigate = useNavigate();
    let user_info = JSON.parse(localStorage.getItem('user_info'));
    let remain_dist = user_info.level.exp - user_info.exp;

    useEffect(() => {
        const allImagesLoaded = checkImagesLoaded();
        setImagesLoaded(allImagesLoaded);
    }, []);

    useEffect(() => {
        setFooterVisible(true);
        const updateHeights = () => {
            if (parrentRef.current) {
                let rectParrent = parrentRef.current.getBoundingClientRect();
                parrentRef.current.style.height = window.innerHeight - rectParrent.y + "px";
            }
            if (childrenRef.current) {
                let rectChildren = childrenRef.current.getBoundingClientRect();
                childrenRef.current.style.height = window.innerHeight - rectChildren.y - 20 + "px";
            }
            if (scrollRef.current) {
                let rectScroll = scrollRef.current.getBoundingClientRect();
                scrollRef.current.style.height = window.innerHeight - rectScroll.y - 20 + "px";
            }
        };

        const images = document.getElementsByTagName('img');
        let imagesLoaded = 0;

        const checkAllImagesLoaded = () => {
            imagesLoaded++;
            if (imagesLoaded === images.length) {
                updateHeights();
            }
        };

        Array.from(images).forEach(img => {
            if (img.complete) {
                checkAllImagesLoaded();
            } else {
                img.addEventListener('load', checkAllImagesLoaded);
            }
        });

        // Добавляем обработчик события resize
        window.addEventListener('resize', updateHeights);

        // Вызываем updateHeights один раз при первой загрузке компонента
        updateHeights();

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            window.removeEventListener('resize', updateHeights);
            Array.from(images).forEach(img => {
                img.removeEventListener('load', checkAllImagesLoaded);
            });
        };
    }, [selectedRoute]);

    const isGettingTrip = useRef(true);

    useEffect(() => {
        let status = true;
        const fetchTrip = async (tripId) => {
            try {
                if (isGettingTrip.current) {
                    const tripResponse = await fetch('api/trip/' + tripId);
                    const tripInfo = await tripResponse.json();
                    console.log(tripInfo);
                    delete window.Telegram.WebApp.initDataUnsafe.start_param;
                    const user_info = JSON.parse(localStorage.getItem('user_info'))
                    const isUserRegistered = tripInfo.registered_users.some(user => user.user.tg_id === user_info.tg_id);
                    setRegistrTrip(isUserRegistered)
                    setSelectedRoute(tripInfo);
                    return false;
                }
            } catch {
                console.log("No info about trip");
            }

            return true;
        }

        if (window.Telegram && window.Telegram.WebApp && closeStart && isGettingTrip.current) {
            const webApp = window.Telegram.WebApp;
            if (webApp.initDataUnsafe.start_param) {
                let tripBase64 = webApp.initDataUnsafe.start_param;
                const tripObject = JSON.parse(atob(tripBase64));
                console.log(tripObject);
                status = fetchTrip(tripObject.trip);
            }
        }

        return () => {
            isGettingTrip.current = status;
        };

    }, [closeStart])


    const handleEntry = (route) => {
        setRate(route);
        navigate('/rate_route');
    };

    const handleButtonClick = (route) => {
        setFooterVisible(false);
        setRegistrTrip(true);
        setSelectedRoute(route.trip);
    };

    const handleCloseClick = () => {
        setSelectedRoute(null);
        setFooterVisible(true);
    };
    return (
        <>
            <div className={register !== undefined && !register && !closeStart ? 'glass' : {}} style={style}>
                {register !== undefined && !register && !closeStart && (
                    <button className="closeGlass" onClick={closeGlass}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8281 10L19.4141 3.41406C19.7917 3.03645 20 2.53387 20 2C20 1.46613 19.7917 0.963555 19.4141 0.585938C19.0364 0.20832 18.5339 0 18 0C17.4661 0 16.9636 0.20832 16.5859 0.585938L10 7.17188L3.41406 0.585938C3.03645 0.20832 2.53387 0 2 0C1.46613 0 0.963555 0.20832 0.585938 0.585938C0.20832 0.963555 0 1.46613 0 2C0 2.53387 0.20832 3.03645 0.585938 3.41406L7.17188 10L0.585938 16.5859C0.20832 16.9635 0 17.4661 0 18C0 18.5339 0.20832 19.0364 0.585938 19.4141C0.96418 19.7917 1.46613 20 2 20C2.53387 20 3.03582 19.7917 3.41406 19.4141L10 12.8281L16.5859 19.4141C16.9642 19.7917 17.4661 20 18 20C18.5339 20 19.0358 19.7917 19.4141 19.4141C19.7917 19.0365 20 18.5339 20 18C20 17.4661 19.7917 16.9636 19.4141 16.5859L12.8281 10Z" />
                        </svg>
                    </button>
                )}

                {register !== undefined && !register && !closeStart && (
                    <>
                        <img src={union} className='union' alt='union' />
                        <div className='forStart'>{t('before')}</div>
                    </>
                )}
            </div>
            <div ref={parrentRef} className="home_body" >
                <div className='home_stat'>
                    <div className='home_level'>{user_info.level.id} {t('level')}</div>
                    <div className='home_done'>
                        <div className='home_distanse_user'>{user_info.exp} xp</div>
                        <div className='home_distanse_need'>{t('still')} {remain_dist} xp</div>
                    </div>
                    <div className='home_progress'>
                        <progress value={user_info.exp} max={user_info.level.exp} />
                    </div>
                </div>


                <div className='home_history'>
                    <h3>{t('history')}</h3>
                    <div ref={scrollRef} className='home_scroll'>
                        {history.length === 0 ? (
                            <div ref={childrenRef} className='home_search_result'>

                                <h2>{t('found')}</h2>
                                <p>{t('see')}</p>
                            </div>
                        ) : (

                            history.map(item => (
                                <div key={item.id} className="search_result_item">
                                    <div className='result_item_left'>
                                        <span className='result_item_id'>{t('route')} №{item.trip.id}</span>
                                        <p>{item.trip.date}</p>
                                        {lang === 'ru' ? (
                                            <h4>{item.trip.name}</h4>
                                        ) : (
                                            <h4>{item.trip.name_en}</h4>
                                        )}

                                        <span className='result_item'>{item.trip.distance} {t('km')} {item.trip.time_sp} {t('min')}</span>
                                    </div>
                                    {item.state === 'W' ? (
                                        <button className='res_item_button result_about_button' style={{width: lang === 'en' && '75px'}} onClick={() => handleButtonClick(item)}>{t('more')}</button>
                                    ) : (
                                        null
                                    )}
                                    {item.state === 'Q' || item.state === 'Y' || item.state === 'R' ? (
                                        <button className='res_item_button result_rate_button' onClick={() => handleEntry(item)}>{t('estimate')}</button>
                                    ) : (
                                        null
                                    )}
                                    {item.state === 'E' ? (
                                        <button className='res_item_button result_end_button' onClick={() => handleEntry(item)}>{t('finished')}</button>
                                    ) : (
                                        null
                                    )}

                                </div>
                            ))

                        )}
                    </div>
                </div>
                {selectedRoute && (
                    <TripRun selectedRoute={selectedRoute} user_info={user_info} handleCloseClick={handleCloseClick} customClass="custom-top-style" registrTrip={registrTrip} t={t}/>
                )}
            </div>
        </>
    )
}

export default Home;