import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import  team  from '../../../img/team.svg'
import '../appRules/Rules.css';

export default function Rules({ t }) {
    const bodyRef = useRef();

    useEffect(() => {
        let rectBody = bodyRef.current.getBoundingClientRect();
        bodyRef.current.style.height = window.innerHeight - rectBody.y + "px";
    }, []);

    return (
        <div ref={bodyRef} id="horizontal-scroller" className="rules_body">
            <h1>{t('about')}</h1>
            <div className="points">
                <div className="point">
                    <p className="post">{t('author')}</p>
                    <Link to={"https://t.me/a1vbg"}>
                        <p className="rule cube">
                            @a1vbg
                        </p>
                    </Link>
                </div>
                <div className="point">
                <p className="post">{t('designer')}</p>
                    <Link to={"https://t.me/OlyaChistaya"}>
                        <p className="rule cube">
                            @OlyaChistaya
                        </p>
                    </Link>
                </div>
                <div className="point">
                <p className="post">{t('developer')}</p>
                    <Link to={"https://t.me/alisleb"}>
                        <p className="rule cube">
                            @CubeIT
                        </p>
                    </Link>
                </div>
            </div>
            <img src={team} alt="team" style={{width: 'calc(100% - 20px)'}} />
            <p className="update_page">{t('updated')}: 09.07.2024 16:30</p>
        </div>
    )
}